import React, { useEffect, useState } from 'react';
import DataImageSection from './components/DataImageSection/DataImageSection';
import ExpertReview from './components/ExpertReview/ExpertReview';
import FeatureListSection from './components/FeatureListSection/FeatureListSection';
import FeatureSection from './components/FeatureSection/FeatureSection';
import Hero from './components/Hero/Hero';
import PriceCard from './components/PriceCard/PriceCard';
import Footer from './components/Footer/Footer';
import { Trans, useTranslation } from 'react-i18next';
import DocumentMeta from 'react-document-meta';
import Subscription from '../../lib/Subscription';
import FAQ from '../../components/FAQ/FAQ';
import { Link } from "react-router-dom";
import Locale from '../../lib/Locale';
import { downloadGuide } from './actions';


const NewLandingPage = () => {
  const { t } = useTranslation("newLandingPage")
  const [subscriptions, setSubscriptions] = useState([])

  const dataForDataImageSection = [
    {
      icon: "img/newLandingPage/icon/Cassette_Icon_Red.png",
      title: t("Je klachten")
    },
    {
      icon: "img/newLandingPage/icon/Cassette_Icon_Red.png",
      title: t("Je vraagt")
    },
    {
      icon: "img/newLandingPage/icon/Cassette_Icon_Red.png",
      title: t("Je weet")
    },
    {
      icon: "img/newLandingPage/icon/Cassette_Icon_Red.png",
      title: t("Je zoekt")
    },
    {
      icon: "img/newLandingPage/icon/Cassette_Icon_Red.png",
      title: t("Je zoekt een")
    },
  ]
  const dataForDataImageSection2 = [
    {
      icon: "img/newLandingPage/icon/Cassette_Icon_Red.png",
      title: t("Geniet meer")
    },
    {
      icon: "img/newLandingPage/icon/Cassette_Icon_Red.png",
      title: t("Snel, goedkoop")
    },
    {
      icon: "img/newLandingPage/icon/Cassette_Icon_Red.png",
      title: t("Money_Back_Guarantee")
    }
  ]
  const customCardData = [
    {
      icon: "img/newLandingPage/icon/Faster_Icon.png",
      title: t("Sneller"),
      description: t("Makkelijk thuis")
    },
    {
      icon: "img/newLandingPage/icon/Cheaper_Icon.png",
      title: t("Goedkoper"),
      description: t("Bespaar ongeveer")
    },
    {
      icon: "img/newLandingPage/icon/Verified_Icon.png",
      title: t("Betrouwbaar"),
      description: t("Niet tevreden")
    },
  ]
  const customCardData2 = [
    {
      title: t("Start de bikefit"),
      description: t("Start de bikefit en")
    },
    {
      title: t("Stel je"),
      description: t("Stel je fiets")
    },
    {
      title: t("Geniet van het fietsen"),
      description: t("Ga op weg")
    },
  ]
  const faqData = [
    {
      question: t("what_is_bike_fitting"),
      answer: <p>{t("bike_fitting_description")}</p>,
    },
    {
      question: t("need_bike_fitting"),
      answer: <p>{t("bike_fitting_recommendation")} <a href={Locale.getUrlForCurrentLocale('/road-frame-size')}>{t("frame_size_question")}</a></p>,
    },
    {
      question: t("bike_fitting_types"),
      answer: <div>
        <p>{t("bike_fitting_categories")}</p>
        <ul>
          <li>
            <Trans i18nKey="newLandingPage:freeOnlineTools">
              <strong>Free online tools</strong> – A good starting point, but not very reliable.
            </Trans>
          </li>
          <li>
            <Trans i18nKey="newLandingPage:paidBikeFittingTools">
              <strong>Paid bike fitting tools</strong> – Often highly accurate but lack physical assessment.
            </Trans>
          </li>
          <li>
            <Trans i18nKey="newLandingPage:physicalBikeFitting">
              <strong>Physical bike fitting</strong> – Recommended when a physical evaluation is necessary, but more expensive.
            </Trans>
          </li>
        </ul>
        <Trans i18nKey="newLandingPage:bike_fitting_pdf_info" components={[<Link to='#' key="0" onClick={() => downloadGuide(t)} />]}>
          <p>
            <p>You can find more information about this in our PDF: <a>6 Key things to consider in a Bike fitting</a></p>
          </p>
        </Trans>
      </div>,
    },
    {
      question: t("can_do_bike_fitting_myself"),
      answer: <p>{t("bike_fitting_self_explanation")}</p>,
    },
    {
      question: t("professional_bike_fitting_cost"),
      answer: <p>{t("bike_fitting_cost_explanation")}</p>,
    },
    {
      question: t("professional_bike_fitting_duration"),
      answer: <p>{t("bike_fitting_duration_explanation")}</p>,
    },
    {
      question: t("bike_fitting_before_buying_bike"),
      answer: <Trans i18nKey="newLandingPage:bikeFittingRecommendation" components={[<Link to={Locale.getUrlForCurrentLocale('/road-frame-size')} key="0" />]}>
        <p>
          If you currently own a similar bike to the one you plan to buy and you are 100% satisfied with your current riding position, a bike fitting may not be necessary. You can simply transfer the measurements from your current bike. <br />
          However, if you don’t have a comparable bike or are unsure about your current riding position, a bike fitting is highly recommended before purchasing a new bike. Standard sizing charts based on height or leg length are often unreliable. Read more in our article: <a href='/road-frame-size/'>Which frame size do I need?</a>
        </p>
      </Trans>
    },
    {
      question: t("dynamic_or_static_bike_fitting"),
      answer: <div>
        <ul>
          <li>
            <Trans i18nKey="newLandingPage:dynamic_bike_fitting">
              <strong>Dynamic bike fitting</strong> – This method involves filming you while riding on a fitting bike or your
              own bike. In theory, it is the best method as it assesses your actual cycling position. However,
              it has a higher risk of measurement errors.
            </Trans>
          </li>
          <li>
            <Trans i18nKey="newLandingPage:dynamic_bike_fitting2">
              <strong>Static bike fitting</strong> – This method involves measurements taken while stationary, making it
              simpler and more accurate. However, it does not account for potential deviations in your
              cycling style.
            </Trans>
          </li>
        </ul>
      </div>,
    },
    {
      question: t("bike_fitting_vs_frame_size"),
      answer: <p>{t("bike_fitting_vs_frame_size_details")}</p>,
    },
    {
      question: t("incorrect_bike_fitting_discomfort_injuries"),
      answer: <p>{t("incorrect_bike_fitting_impact")}</p>,
    },
    {
      question: t("how_bike_fitting_impacts_performance"),
      answer: <p>{t("bike_fitting_performance_impact")}</p>,
    },
    {
      question: t("how_often_bike_fitting"),
      answer: <p>{t("bike_fitting_factors")}</p>,
    },
    {
      question: t("are_online_bike_fitting_tools_reliable"),
      answer: <div>
        <p>{t("online_bike_fitting_tools_reliability")}</p>
        <ul>
          <li>
            <Trans i18nKey="newLandingPage:Free_bike_fitting_tools">
              <strong>Free bike fitting tools</strong> are marketing instruments for another product. Since bike fitting is not
              the core business of these companies, their expertise and development budgets are limited.
              Limiting the reliability and customer service of these tools.
            </Trans>
          </li>
          <li>
            <Trans i18nKey="newLandingPage:Free_bike_fitting_tools2">
              <strong>Paid bike fitting tools,</strong> on the other hand, focus entirely on bike fitting as their core business.
              Since their existence depends on their accuracy and reliability, their customer service and
              precision are usually much better.
            </Trans>
          </li>
        </ul>
      </div>,
    },
    {
      question: t("Can_a_bike"),
      answer: <p>{t("Can_a_bike_ans")}</p>,
    },
    {
      question: t("Which_components_are"),
      answer: <div>
        <p>{t("Which_components_are_des")}</p>
        <ul>
          <li>{t("Cleat_position")}</li>
          <li>{t("Saddle_tilt")}</li>
          <li>{t("Handlebar_width")}</li>

        </ul>
      </div>,
    },
    {
      question: t("How_does_a_bike"),
      answer: <p>{t("A_bike_fitting")}</p>,
    },
    {
      question: t("Can_a_bike_fitting"),
      answer: <p>{t("Yes_A_bike_fitting")}</p>,
    },
  ];
  const meta = {
    title: t("Bike_Fitting_meta_title"),
    description: t("Bike_Fitting_meta_description"),
  };

  useEffect(() => {
    Subscription.getSubscriptions('bikefit')
      .then(subscriptions => {
        setSubscriptions(subscriptions)
      })
  }, [t])

  return (
    <div className='bg-white'>

      <DocumentMeta {...meta} />

      <Hero subscriptions={subscriptions} />

      <DataImageSection
        data={dataForDataImageSection}
        imagePath={`img/newLandingPage/hero/${t("back-pain-bike-fitting")}.webp`}
        title={t("Herken je dat")}
      />

      <FeatureSection
        title={t("Online Bikefitting")}
        styledWord="Bikefitting: Online Bike Fitting:"
        description={t("Ontdek de voordelen")}
        customCardData={customCardData}
      />

      <FeatureListSection
        title={t("Wij begrijpen")}
        styledWord="pijn of klachten pain or discomfort"
        description={t("Bij Best Bike")}
      />

      <FeatureSection
        title={t("In slechts")}
        styledWord="genieten Enjoy"
        customCardData={customCardData2}
        badgeStyle={{ backgroundColor: '#F4DE58' }}
      />

      <PriceCard
        _subscriptions={subscriptions}
        t={t}
      />

      <ExpertReview t={t} />

      <DataImageSection
        imagePath={`img/newLandingPage/hero/${t('enjoy-bike-fitting')}.webp`}
        title={t("Bij Best Bike Advice")}
        styledWord="maximaal genieten enjoy cycling to the fullest"
        color="#F5755D"
        description={t("Om dat te kunnen")}
      />

      <FeatureSection
        title={t("De 6 Belangrijkste")}
        description={t("Download onze")}
        style={{ backgroundColor: '#F4DE58', color: 'black' }}
        bikeFittingButton={false}
      />


      <DataImageSection
        imagePath={`img/newLandingPage/hero/${t('road-bike-fitting')}.webp`}
        title={t("Doe jouw")}
        styledWord="online Online"
        color="#F5755D"
        style={{ textAlign: 'left', marginLeft: '0px' }}
        data={dataForDataImageSection2}
      />
      <FAQ faqData={faqData} isNewLandingPage={true} title={t("Faq_title")} />
      <Footer />
    </div>
  );
};

export default NewLandingPage;
