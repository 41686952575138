import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import Footer from '../Footer';
import Login from '../Auth/Login';
import Auth from '../../lib/Auth';
import Redirections from '../../lib/Redirections';
import Locale from '../../lib/Locale';
import Newsletter from '../../lib/Newsletter';


const BikefitLogin = () => {
    const [t] = useTranslation("subscription");
    const isLoggedIn = Auth.isLoggedIn();

    const [firstName] = useState(Newsletter.getLocalEntry().firstname || '')
    const [lastName] = useState(Newsletter.getLocalEntry().lastname || '')
    const [email, setEmail] = useState(Newsletter.getLocalEntry().email || '')
    const [optinMail] = useState(Newsletter.getLocalEntry().optinMail || false)
    const [optinData] = useState(Newsletter.getLocalEntry().optinData || false)
    const [policy] = useState(Newsletter.getLocalEntry().policy || false)

    const inputEmail = e => setEmail(e.target.value)

    if (isLoggedIn) {
        Redirections.replaceTo(Locale.getUrlForCurrentLocale('/subscription/payment/data/'));
        return <div></div>
    }

    const handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
          }
    
          Newsletter.setEntry({
            firstname: firstName,
            lastname: lastName,
            email: email,
            optinMail: optinMail,
            optinData: optinData,
            policy: policy
          });

          Redirections.replaceTo(Locale.getUrlForCurrentLocale('/subscription/payment/data/'));
        }

    return (
        <div className="main bg-dark checkout">
            <div className='container'>
                <div className='col-12'>
                    <div className='col-12 text-center text-white'>
                        <h1 className='text-white mt-lg-5'>
                            {t('subscription:Account_EmailInUse')}
                        </h1>
                        <p className="text-white">
                            {t('subscription:Account_EmailDoesExist')}
                        </p>
                    </div>
                    <div className="container bg-white tabs-section" id="tab-section">
                        <Tabs className="nav-justified">
                            <Tab label={t('subscription:LoginTab')}>
                                <Login IgnoreRemoteData={true} redirectUrl={'/subscription/payment/data/'} />
                            </Tab>
                            <Tab label={t('subscription:LoginChangeEmailTab')}>
                                <p>{t('subscription:Account_EmailChange')}</p>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="email" onChange={inputEmail} className="form-control"
                                                    placeholder={t('Account_Email')} value={email} name="email" required />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group text-center">
                                                <input type="submit" name="btnSubmit" className="btn btn-secondary" disabled={Auth.isLoggedIn()}
                                                    value={t('USERINFO_PAGE_SUBMIT')} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default BikefitLogin;