import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setExtraMeasurementBikeSelection, setExtraMeasurementBike} from "../../redux/actions/extraMeasurements";
import Api from "../../lib/Api";
import bikeTypeSupportsFitting from "../Bikefinder/FittingSupport";

const BikeSelection = () => {
  const [t,] = useTranslation("framemaatAdvice");
  const dispatch = useDispatch();

  const category = useSelector(store => store.extraMeasurements?.bikeMeasurements?.category || 'MTB')
  const bikeType = useSelector(store => store.extraMeasurements?.bikeSelection?.bikeType || '')
  const year = useSelector(store => store.extraMeasurements?.bikeSelection?.year || '')
  const brand = useSelector(store => store.extraMeasurements?.bikeSelection?.brand || '')
  const model = useSelector(store => store.extraMeasurements?.bikeSelection?.model || '')


  const [bikeTypeOptions, setBikeTypeOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);

  const updateBikeTypeOptions = (options) => {
    setBikeTypeOptions(options)

    if ( ! options.includes(bikeType)) {
      setValue('bikeType', '')
    }
  }

  const updateBrandOptions = (options) => {
    setBrandOptions(options)

    if ( ! options.includes(brand)) {
      setValue('brand', '')
    }
  }

  const updateModelOptions = (options) => {
    setModelOptions(options.sort())

    if ( ! options.includes(model)) {
      setValue('model', '')
    }
  }

  const setSelectOptions = (url, key, selectedValue, fxn) => {
    Api.get(url, {geoRequired: true}).then(response => {
      let list = response?.data?.response?.data || []
      fxn(list.filter(item => bikeTypeSupportsFitting(item)))
    })
  }

  const setValue = (type, value) => {
    dispatch(setExtraMeasurementBikeSelection(type, value));
  }

  const setBike = (bike) => {
    dispatch(setExtraMeasurementBike(bike));
  }

  useEffect(() => {
    if (category) {
      setSelectOptions('/bike-info/' + category + '/types', 'bikeType', "", updateBikeTypeOptions)
    } else {
      setValue('bikeType', '')
    }
  }, [category])

  useEffect(() => {
    if (!bikeType) {
      setValue('year', '')
    }
  }, [bikeType])

  useEffect(() => {
    if (bikeType && year) {
      setSelectOptions('/bike-info/' + bikeType + '/' + year + '/brands', 'brand', brand, updateBrandOptions)
    } else {
      setBrandOptions([])
      setValue('brand', '')
    }

  }, [year, bikeType])

  useEffect(() => {
    if (brand) {
      setSelectOptions('/bike-info/' + bikeType + '/' + year + '/' + brand + '/models', 'model', model, updateModelOptions)
    } else {
      setModelOptions([])
      setValue('model', '')
    }
  }, [brand])

  useEffect(() => {
    if (model) {
      getBikeDetails(bikeType, year, brand, model, setBike)
    } else {
      setBike("")
    }
  }, [model])

  const getBikeDetails = (bike_type, year, brand, model, fxn) => {
    Api.get('/bike-info/' + bike_type + '/' + year + '/' + brand + '/' + model + '/bikes').then(response => {
      let data = response.data ? response.data : {};
      let res = data.response ? data.response : {}
      let info = res.data ? res.data["0"] : "";
      fxn(info)
    })
  }

  return (
      <>
        <div className="col-md-12">
          <div className="row m-2">
            <div className="col-md-5">
              <label className="form-check-label">{t('BikeType')}
              </label>
            </div>
            <div className="col-md-7">
              <div className="row">
                <div className="col-md-11">
                  <select className="form-control" value={bikeType} onChange={(event) => {
                    setValue('bikeType', event.target.value)
                    setValue('year', '')
                  }} disabled={!bikeTypeOptions.length}>
                    {bikeTypeOptions.length && <option value=''>{t('select one')}</option>}
                    {bikeTypeOptions.map(option => <option key={'biketype_' + option} value={option}>{t(option)}</option>)}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row m-2">
            <div className="col-md-5">
              <label className="form-check-label">{t('Year')}
              </label>
            </div>
            <div className="col-md-7">
              <div className="row">
                <div className="col-md-11">
                <select className="form-control" value={year} onChange={(event) => {
                  setValue('year', event.target.value)
                  setValue('brand', '')
                }} disabled={bikeTypeOptions.length === 0 || !bikeType}>
                  <option value="">{bikeType ? t('select one') : ''}</option>,
                  <option value="2024">2024</option>,
                  <option value="2023">2023</option>,
                  <option value="2022">2022</option>,
                  <option value="2021">2021</option>,
                  <option value="2020">2020</option>,
                  <option value="2019">2019</option>
                </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row m-2">
            <div className="col-md-5">
              <label className="form-check-label">{t('Brand')}
              </label>
            </div>
            <div className="col-md-7">
              <div className="row">
                <div className="col-md-11">
                  <select className="form-control" value={brand} onChange={(event) => {
                    setValue('brand', event.target.value)
                    setValue('model', '')
                  }} disabled={!brandOptions.length}>
                    {brandOptions.length && <option value=''>{t('select one')}</option>}
                    {brandOptions.map(option => <option key={'brand_' + option} value={option}>{t(option)}</option>)}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row m-2">
            <div className="col-md-5">
              <label className="form-check-label">{t('Model')}
              </label>
            </div>
            <div className="col-md-7">
              <div className="row">
                <div className="col-md-11">
                  <select className="form-control" value={model} onChange={(event) => setValue('model', event.target.value)} disabled={!modelOptions.length} >
                    {modelOptions.length && <option value=''>{t('select one')}</option>}
                    {modelOptions.map(option => <option key={'model_' + option} value={option}>{t(option)}</option>)}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}

export default BikeSelection;
