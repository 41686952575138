import React from 'react';
import DynamicTitleDescription from '../DynamicTitleDescription/DynamicTitleDescription';
import IconTitleList from '../Lists/IconTitleList/IconTitleList';
import BikeFittingButton from '../button/BikeFittingButton';
import LightGreyContainer from '../containers/LightGreyContainer/LightGreyContainer';
import { useTranslation } from 'react-i18next';

const DataImageSection = ({ data, imagePath, title, styledWord, description, color, style }) => {
    const { t } = useTranslation("newLandingPage")
    const shouldImgPositionRight = imagePath === `img/newLandingPage/hero/${t("back-pain-bike-fitting")}.webp`
    return (
        <LightGreyContainer>
            <div className="row p-0 m-0">
                <div className="col-lg-8 px-0 pr-2 pr-xl-4 pb-4 pb-lg-0">
                    <div className='text-left'>
                        <DynamicTitleDescription title={title} styledWord={styledWord} description={description} color={color} classNames={'text-left'} />
                        <IconTitleList data={data} />
                        <BikeFittingButton buttonColor="#F5755D"
                            textColor="white" />

                    </div>
                </div>
                <div className="col-lg-4 overflow-hidden px-0 ">
                    <div className='h-100 d-flex align-items-center'>

                        <img src={imagePath} className={`${shouldImgPositionRight ? 'img-fit-right' : styledWord === 'online Online' ? 'w-100' : 'img-fit'}  float-end`} alt={shouldImgPositionRight ? t("backPainBikeFitting") : styledWord === 'online Online' ? t("roadBikeFitting") : t("enjoyBikeFitting")} style={{ borderRadius: '15px' }} />
                    </div>
                </div>


            </div>
        </LightGreyContainer>
    );
};

export default DataImageSection;