import React, { useEffect, useState } from 'react';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import DynamicTitleDescription from '../DynamicTitleDescription/DynamicTitleDescription';
import BikeFittingButton from '../button/BikeFittingButton';
import Trustpilot from '../../../../components/Reviews/Trustpilot';
import { useTranslation } from 'react-i18next';
import './style.css'
import { downloadGuide } from '../../actions';
import useScreenSize from '../../../../hooks/useScreenSize';
import { useOpenFeature } from '../../../../open-feature/OpenFeatureContext';
import CurrencyFormatter from '../../../../lib/CurrencyFormatter';

const Hero = ({ subscriptions }) => {
  const { t } = useTranslation("newLandingPage")
  const sm = useScreenSize(576);

  const [priceFeature, setPriceFeature] = useState(false);
  const [featureData, setFeatureData] = useState([]);
  const [bikefitOnce, setBikefitOnce] = useState({});

  const { openFeatureClient } = useOpenFeature();

  const loadFeatureValue = async () => {
    const featureValue = await openFeatureClient.getBooleanValue('show_price', false);
    setPriceFeature(featureValue);
  }

  useEffect(() => {
    if (openFeatureClient) {
        loadFeatureValue();
    }
  }, [openFeatureClient]);

  useEffect(() => {
    const data = [
      {
        type: "feature",
        title: '10.000+',
        description: t("Succesvolle bikefittings")
      }
    ];

    if (priceFeature) {
      data.push({
        type: "feature",
        title: CurrencyFormatter.format(bikefitOnce?.price?.price ? bikefitOnce.price.price / 100 : 0, bikefitOnce?.price?.currency),
        description: t("Betaalbare bikefitting")
      });
    } else {
      data.push({
        type: "trustpilot"
      });
    }

    data.push({
      type: "feature",
      title: '15 min',
      description: t("Snel goed op de fiets")
    });

    setFeatureData(data);
  }, [priceFeature, subscriptions, bikefitOnce, t]);

  useEffect(() => {
    setBikefitOnce(subscriptions?.find(subscription => subscription.code === 'bikefit_once'))
  }, [subscriptions])

  const Feature = ({title, description}) => (
    <div className='text-white text-md-center text-left py-1'>
      <p className='m-0 font-bold text-xl'>{title}</p>
      <p className='m-0 px-0 px-md-2 text-l font-bold'>{description}</p>
    </div>
  )

  const TrustPilotFeature = () => (
    <div className='text-white text-md-center text-left py-1 d-flex flex-column justify-content-center'>
      <Trustpilot />
    </div>
  )

  return (
    <>
      <div style={{ height: sm ? 'calc(100vh - 150px) ' : 'calc(100vh - 240px )', width: '100%' }} className=' position-relative heroCarouselWrapper'>
        <img className='img-fit' src={`img/newLandingPage/hero/${t('main-bike-fitting')}.webp`} alt={t("mainBikeFittingAlt")} />

        <div className='container p-md-0 p-3 position-absolute h-100 d-flex flex-column justify-content-around' style={sm ? { top: '0', width: '100%' } : { left: '0%', right: '0%', top: '0' }}>

          <div className='text-left heroTitleDescriptionButtonWrapper'>
            <DynamicTitleDescription description={t("heroDescription")} title={t("heroTitle")} styledWord="bikefitting Bike Fitting" color="#F4DE58" classNames="text-left text-white" isHeader={true}
            />
            <BikeFittingButton buttonColor="#F4DE58" />
          </div>

          <div className='trustpilot-wrapper d-block d-sm-flex justify-content-between'>
            <div className="ratingWrapper mb-3">
              {priceFeature && <Trustpilot />}
            </div>

            {/* download guide */}
            <div className='d-flex downloadGuideParent' onClick={() => downloadGuide(t)}>
              <div className="position-relative bg-white text-left py-4 px-3 m-0 text-l border-10px" >
                <p className='m-0 font-weight-bold' >{t("dCardTitle")}</p>
                <p className='m-0'>{t("dCardDescription")}</p>
                <div className='d-flex justify-content-center align-items-center absolute-circle'>
                  <img style={{ width: '60%' }} src="/img/newLandingPage/icon/Downlaod_Icon.png" alt="" />
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

      <div className='bg-red-orange'>
        <div className='d-flex justify-content-between py-sm-4 py-1 container'>
          {featureData?.map(
            (feature, index) => feature.type === "trustpilot" ? <TrustPilotFeature key={`features-${index}`} /> :
              <Feature key={`features-${index}`} title={feature.title} description={feature.description} />
          )}
        </div>
      </div>
    </>
  );
};

export default Hero;
