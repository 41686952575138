import React, {useEffect, useState} from "react";
import DocumentMeta from "react-document-meta";
import Api from "../../lib/Api";
import {useTranslation} from "react-i18next";
import Footer from "../../components/Footer";
import Locale from "../../lib/Locale";
import {Link} from "react-router-dom";
import MoreInfo from "../../lib/MoreInfo";

import "./style.css";
import BikeMeasurements from "../../components/ExtraMeasurements/BikeMeasurements";
import {useDispatch, useSelector} from "react-redux";
import BikeSelection from "../../components/ExtraMeasurements/BikeSelection";
import Popup from "../../lib/Popup";
import WarningPopupComponent from "../../components/ExtraMeasurements/DropWarningPopup"
import {setExtraMeasurementBikeValue} from "../../redux/actions/extraMeasurements";
import AdviceSource from "../../components/ExtraMeasurements/AdviceSource";

// images
const mtlbFitEn = "./img/category/mtb_bikefit_en.png";
const mtlbFitNl = "./img/category/mtb_bikefit_nl.png";
const roodFitEn = "./img/category/road_bike_bikefit_en.png";
const roadFitNl = "./img/category/road_bike_bikefit_nl.png";

const FramemaatAdvice = () => {
  const [t,] = useTranslation("framemaatAdvice");

  const dispatch = useDispatch()

  /*feching data from framemaat*/
  const category = useSelector(store => store.extraMeasurements?.bikeMeasurements?.category || 'MTB')
  const transCat = category === 'MTB' ? 'mtb' : 'road'
  const saddleHeight = useSelector(store => store.extraMeasurements?.bikeMeasurements?.[transCat]?.['saddleHeight'] || '')
  const saddleSetback = useSelector(store => store.extraMeasurements?.bikeMeasurements?.[transCat]?.['saddleSetback'] || '')
  const handlebarReach = useSelector(store => store.extraMeasurements?.bikeMeasurements?.[transCat]?.['handlebarReach'] || '')
  const personLength = useSelector((store) => store.extraMeasurements?.bikeMeasurements?.[transCat]?.["personLength"] || "");
  const drop = useSelector(store => store.extraMeasurements?.bikeMeasurements?.[transCat]?.['drop'] || '')
  const bike = useSelector(store => store.extraMeasurements?.bikeSelection?.bike)
  const [size, setSize] = useState("");
  const [message, setMessage] = useState("");
  const [dropNegated, setDropNegated] = useState(false)
  const [calculateFrameSize, setCalculateFrameSize] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (calculateFrameSize) {
      document.getElementById('frameSizeAdvice').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
    }
  }, [calculateFrameSize])

  useEffect(() => {
    if (dropNegated) {
      afterSubmit()
      setDropNegated(false)
    }
  }, [dropNegated])

  const meta = {
    title: t("FrameSizeAdvies"),
    description: t("text1") + t('text1red'),
  };

  const changeDropFromPopup = () => {
    let updatedDrop = -1 * drop;
    dispatch(setExtraMeasurementBikeValue('drop', updatedDrop));
    Popup.close();
    setDropNegated(true)
  }

  const closePopup = () => {
    Popup.close();
    setTimeout(afterSubmit, 200);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (drop > 0) {
      Popup.show(<WarningPopupComponent close={closePopup} changeDrop={changeDropFromPopup}/>, true);
    } else {
      afterSubmit();
    }
    setSubmitted(!submitted);
  }

  const afterSubmit = () => {
    let data = {
      "saddle_height": saddleHeight * 10,
      "saddle_setback": saddleSetback * 10,
      "bike_length": handlebarReach * 10,
      "person_length": personLength * 10,
      "bike_drop": drop * 10
    }

    Api.post("measurements/cockpit/for-bike/" + bike.summary.bike_id, data)
      .then((response) => {
        let msgKey = response.data.response.data.fit_summary ? "FRAMESIZE_ADVICE" + response.data.response.data.fit_summary.split(":")[0] : 'NoFitMessage';

        setSize(response.data.response.data.bike_size);
        setMessage(t(msgKey));
      })
      .catch((error) => {
        alert(error);
      });
    if (calculateFrameSize) {
      document.getElementById('frameSizeAdvice').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
    } else
      setCalculateFrameSize(true);
  }

  const disable = !bike || !saddleHeight || !saddleSetback || !handlebarReach || !drop;

  return (
    <div className={"FrameSize bike-comparision " + Locale.getLanguage()}>
      <DocumentMeta {...meta} />
      <div className="container pb-5">
        <div className="row justify-content-center">
          <h1 className="m-4">{t("FrameSizeAdvies")}</h1>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            {t('text1')}<Link to={Locale.getUrlForCurrentLocale("/road-frame-size")}
                              className="text-red">{t('text1red')}</Link>
            <br/>
            <br/>
            {t('text2')}<Link to={Locale.getUrlForCurrentLocale("/bikefiting/")} className="text-red">{t('text2red')}</Link>
          </div>
        </div>
      </div>
      <div className="container step-section">
        <form className="framemaat-advice-form" onSubmit={handleSubmit}>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6 framemaat-left">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row mb-5">
                      <div className="col-xl-6 left-heading-hr">
                        <img src="/img/home/hrline.jpg" alt="line" style={{"width": "inherit"}}/>
                      </div>
                      <div className="col-xl-6 heading">
                        <h2>{t('bikeFitHeading')}</h2>
                      </div>
                    </div>
                  </div>
                  <div>
                    <BikeMeasurements/>
                  </div>
                  <div className="col-md-12 pt-3">
                    <div className="row m-2">
                      <div className="col-lg-12">
                        <p>
                          <strong>{t('bikeFitpara1')}</strong>
                          <span
                            className="link moreInfo"
                            onClick={() => {
                              MoreInfo.show("info", category === "Race" ? "personalfit-road" : "personalfit");
                            }}
                          >
                            {" "}{t("Meer informatie")}
                          </span>
                          </p>
                        <br/>
                        <div className="img-box pb-3">
                          {category === "Race" ?
                            <img
                              alt={t("Racebike")}
                              className="img-fluid"
                              src={
                                Locale.getLanguage() === "nl"
                                  ? roadFitNl
                                  : roodFitEn
                              }
                            />
                            : category === "MTB" ?
                              <img
                                alt={t("Mountainbike")}
                                className="img-fluid"
                                src={
                                  Locale.getLanguage() === "nl"
                                    ? mtlbFitNl
                                    : mtlbFitEn
                                }
                              />
                              : ""}
                        </div>
                        <br/>
                        <br/>
                        <p className="mb-0"><strong>{t("bikeFitpara2")}</strong></p>
                        <p>{t("bikeFitpara3")}</p>
                        <p className="text-center">
                          <Link className="btn btn-primary btn-lg" to={Locale.getUrlForCurrentLocale("/bikefitting/")}
                                role="button">{t("startBikefit")}</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 framemaat-right">
                <div className="row mb-5">
                  <div className="col-xl-6 heading">
                    <h2>{t('bikeModelHeading')}</h2>
                  </div>
                  <div className="col-xl-6">
                    <img src="/img/home/hrline.jpg" alt="line" style={{"width": "inherit"}}/>
                  </div>
                </div>
                <div className="row">
                  <BikeSelection/>
                  <div className="col-12 pt-3 pb-3 calculateFrameSizeBtn">
                    <input id="calculateFrameSize" className="btn btn-secondary" type="submit"
                           value={t("calculateFrameSize")} disabled={disable}/>
                  </div>
                  {bike ?
                    <div className="col-md-12">
                      <div className="row m-2">
                        <div className="col-lg-12">
                          <div className="top-wrap">
                            <h3 className="text-center">{bike.bike_model.brand + " " + bike.bike_model.model}</h3>
                            <img
                              className="img-responsive mx-auto d-flex"
                              src={"/img/bikes/" + bike.summary.image}
                              alt={
                                "Afbeelding " +
                                bike.bike_model.brand +
                                " " +
                                bike.bike_model.model
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <div className="col-md-12">
                      <div className="row m-2">
                        <div className="col-lg-12">
                          <div className="text-center">{t("textForCockpitAdvice1")}</div>
                          <div className="text-center">{t("textForCockpitAdvice2")}</div>
                          <div className="text-center"><Link to={Locale.getUrlForCurrentLocale("/cockpit-advice")}
                                                             className="text-red">{t("textForCockpitAdviceLink")}</Link>{" "}{t("textForCockpitAdvice3")}
                          </div>
                          <div className="text-center">{t("textForCockpitAdvice4")}</div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div id="frameSizeAdvice">
        {
          calculateFrameSize ? <div className="container py-5">
            <div className="row justify-content-center">
              <h2 className="m-4">{t("frameSizeAdvice")}</h2>
            </div>
            <div className="row">
              <div className="col-md-12 text-center">
                <AdviceSource 
                  category={category}
                  saddleHeight={saddleHeight}
                  saddleSetback={saddleSetback}
                  handlebarReach={handlebarReach}
                  drop={drop} 
                  submitted={submitted}
                  adviceType="frameSizeAdvice"
                />
                <div className="frame-size pt-5 pb-2">{size}</div>
                <div className="">
                        {t("unexpectedFrameSizeInfo_1")}
                        {" "}
                        <Link to={Locale.getUrlForCurrentLocale("/road-frame-size/")} className="link moreInfo">{t('WhatFrameSizeDoINeed')}</Link>
                        
                        {" "}
                        {t("unexpectedFrameSizeInfo_2")}
                      </div>
                {message && <div className="text-red pb-5">{message}</div>}
                <br/>
                <div>{t('adviceText2')}</div>
                <p className="text-center my-4">
                  <Link className="btn btn-primary btn-lg" to={{
                      pathname: Locale.getUrlForCurrentLocale('/cockpit-advice'), state: {
                      category: category,
                      saddle_height: saddleHeight,
                      saddle_setback: saddleSetback,
                      bike_length: handlebarReach,
                      bike_drop: drop
                    }
                  }}>{t("viewTuningDetails")}</Link>
                </p>
                <br/>
                <br/>
                <div>{t('adviceText3')}<Link to={Locale.getUrlForCurrentLocale("/road-frame-size")}
                                             className="text-red">{t('adviceText3red')}</Link></div>
                <br/>
                {t('adviceText4')}
              </div>
            </div>
          </div> : ""
        }
      </div>
      {/*<div className="step-section text-center pb-2">
                <div className="container ">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h4 className="pt-4">{t("adviceFooterText1")}</h4>
                            <div className="text-red pt-3 pb-5">{t("adviceFooterText2")}</div>
                        </div>
                    </div>
                </div>
            </div>*/}
      <Footer/>
    </div>
  );
}
export default FramemaatAdvice;

