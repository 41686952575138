import React, { useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import "./style.css"

const FAQItem = ({ question, answer, index }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  return (
    <div className={`${index===0 && "custom-border-top"} custom-border-bottom`}>
      <h3 className={`${isOpen && "bg-custom-red"} text-l m-0  p-2`}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="faq-button d-flex justify-content-between w-100"
        >
          {question}
        
        <span>{isOpen ? <FaCaretUp size={12}/> : <FaCaretDown size={12}/>}</span>
      </button>
        </h3>
      <div 
        ref={contentRef}
        className="pl-2 pr-2"
        style={{
          height: isOpen ? `${contentRef.current?.scrollHeight}px` : "0px",
          overflow: "hidden",
          transition: "height 0.3s ease-in-out",
        }}
        >
          {answer}
        </div>
    </div>
  );
};

const FAQ = ({ faqData, isNewLandingPage, title }) => {

  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": faqData.map((faq) => ({
      "@type": "Question",
      "name": faq.question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": ReactDOMServer.renderToStaticMarkup(faq.answer) ,
      },
    })),
  };

  return (
    <div className={`${isNewLandingPage && "bg-dark-gray text-white"} py-5`}>
      <div className="container">
       <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
                __html: JSON.stringify(faqSchema),
            }}
          />
        <h2 className={`text-center ${isNewLandingPage && "h2"}`} mb-3>{title}</h2>
        <div>
          {faqData.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} index={index}/>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
