import React, {useState, useEffect} from 'react';
import {useTranslation, Trans} from "react-i18next";
import reactStringReplace from "react-string-replace";
import Subscription from "../../lib/Subscription";
import './style.css'
import MoreInfo from "../../lib/MoreInfo";
import Redirections from "../../lib/Redirections";
import {useDispatch} from "react-redux";
import {chooseSubscription, setReturnUrl} from "../../redux/actions/checkout";
import CurrencyFormatter from "../../lib/CurrencyFormatter";
import ReactGA from 'react-ga4';

const BikefitPlans =
  ({
     returnUrl,
     onlyPayForAccount = false,
     extension = false,
    extendingAccountSubscriptionId = 0,
    isFreeReport,
    isSimplifiedCheckout
   }) => {
    const [t, i18next] = useTranslation('subscription')

    const [subscriptions, setSubscriptions] = useState([])
    const [filteredSubscriptions, setFilteredSubscriptions] = useState([])
    const dispatch = useDispatch();

    const shouldRedirectDirectly = subscription => {
      return onlyPayForAccount && !subscription.needs_account
    }
    useEffect(() => {
      setFilteredSubscriptions(subscriptions.filter(subscription => (isFreeReport ? subscription.needs_account : true) && (isSimplifiedCheckout ? subscription.code !== 'account' : true)))

    }, [subscriptions, isFreeReport])

    const clickSubscription = subscription => {
      ReactGA.event('add_to_cart', {
        currency: subscription.price.currency,
        value: subscription.price.price / 100,
        items: [{
          item_id: subscription.code,
          item_name: `Product type: ${subscription.type}, code: ${subscription.code}`,
          item_category: subscription.type,
          price: subscription.price.price / 100,
          currency: subscription.price.currency
        }]
      });

      if (shouldRedirectDirectly(subscription)) {
        Redirections.goTo(returnUrl)
      } else {
        dispatch(chooseSubscription(subscription, extension, extendingAccountSubscriptionId))
        dispatch(setReturnUrl(returnUrl))
        Redirections.goTo('/subscription/payment/data')
      }
    }

    const loadSubscriptions = () => {
      Subscription.getSubscriptions('bikefit')
        .then(subscriptions => {
          setSubscriptions(subscriptions.filter(subscription => {
            return extension ? subscription.needs_account : true
          }))
        })
    }

    const extractPointsFromSubscription = subscription => {
      if (!subscription.lang) {
        return '';
      }

      const BenefitLink = () => (
        <span className="moreInfo link" onClick={() => {
          MoreInfo.show('subscription', 'benefits')
        }}><i className="fas fa-caret-right"></i> {t('Meer informatie')}</span>
      )

      const BikefitLink = () => (
        <span className="moreInfo link" onClick={() => {
          MoreInfo.show('subscription', 'bikefit')
        }}><i className="fas fa-caret-right"></i> {t('Meer informatie')}</span>
      )

      let points = subscription.lang.points_html

      points = reactStringReplace(
        points,
        /<li>(.*)<\/li>*/,
        (match, i) => {
          let point = reactStringReplace(
            match,
            '[benefits]',
            (match, i) => <BenefitLink key={i}/>
          )

          point = reactStringReplace(
            point,
            '[bikefit]',
            (match, i) => <BikefitLink key={i}/>
          )

          return <li key={i}><Trans>{point}</Trans></li>
        }
      )

      return points
    }

    useEffect(() => {
      loadSubscriptions();
    }, [i18next.language])

    return (
      <div className="row subscription-plans">
        {
          isFreeReport && 
        <div className="col-12 col-lg subscription-plan">
          <div className={"shadow-column"}>
            <div className="subscription-block subscription-head-block">
              <h2>{t('BIKE_FIT_INSIGHTS')}</h2>
              <span className="subscription-price"><span>{t("FREE")}</span></span>
            </div>
            <div className="subscription-block subscription-pay-button">
              <button className="btn btn-secondary">{t("Bikefit_StartDirectly")}</button>
            </div>
            <div className="subscription-block subscription-points">
              <ul>
                <li>{t('BASIC_BIKE_FIT_INSIGHTS')}</li>
                <li>{t('BIKE_PERSONALIZATION_REQUIREMENTS')}</li>
              </ul>
            </div>
          </div>
        </div>
        }
        {filteredSubscriptions.map(subscription => {
          let buttonText;
          if (shouldRedirectDirectly(subscription)) {
            buttonText = t('Bikefit_StartDirectly');
          } else if (extension) {
            buttonText = t('Bikefit_ExtendAccount');
          } else {
            buttonText = t("Get_report")
          }

          let price = 0;
          let beforePrice = 0;
          const isZeroFractionDigit = true
          if (subscription.price) {
            price = CurrencyFormatter.format(subscription.price.price / 100, subscription.price.currency, isZeroFractionDigit);

            if (subscription.price.before_price) {
              beforePrice = CurrencyFormatter.format(subscription.price.before_price / 100, subscription.price.currency, isZeroFractionDigit);
            }

            if (extension && subscription.price.extension_price) {
              price = CurrencyFormatter.format(subscription.price.extension_price / 100, subscription.price.currency, isZeroFractionDigit);
              beforePrice = CurrencyFormatter.format(subscription.price.price / 100, subscription.price.currency, isZeroFractionDigit);
            }
          } else {
            price = 0
          }

          const points = extractPointsFromSubscription(subscription)

          return (
            <div key={subscription.code} className="col-12 col-lg subscription-plan">
              <div className={"shadow-column" + ((!extension && subscription.highlight) ? ' highlighted' : '')}>
                <div className="subscription-block subscription-head-block">
                  <h2>{subscription.lang ? subscription.lang.title : subscription.code}</h2>

                  {beforePrice !== 0 &&
                  <span className="subscription-price mr-3"><span className="old-price">{beforePrice}</span></span>}
                  <span className="subscription-price"><span>{price}</span></span>
                </div>
                <div className="subscription-block subscription-pay-button">
                  <button className="btn btn-secondary"
                          onClick={() => clickSubscription(subscription)}>{buttonText}</button>
                </div>
                <div className="subscription-block subscription-points">
                  <ul>
                    {points}
                  </ul>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

export default BikefitPlans;
