import React from 'react'
import { useTranslation } from 'react-i18next';
import CurrencyFormatter from '../../lib/CurrencyFormatter';
import ReactGA from 'react-ga4';
import { useDispatch } from 'react-redux';
import { chooseSubscription, setReturnUrl } from "../../redux/actions/checkout";
import Redirections from '../../lib/Redirections';

export default function SimplifiedCheckout({ bikefitOnce, extension, extendingAccountSubscriptionId, returnUrl, TrustScore }) {
  const [t] = useTranslation("subscription");
  const dispatch = useDispatch();

  const bikefitOnceList = [
    "cycle_more_comfortably",
    "improve_your_performance",
    "buy_the_right_bike"
  ];
  const clickSubscription = subscription => {
    ReactGA.event('add_to_cart', {
      currency: subscription.price.currency,
      value: subscription.price.price / 100,
      items: [{
        item_id: subscription.code,
        item_name: `Product type: ${subscription.type}, code: ${subscription.code}`,
        item_category: subscription.type,
        price: subscription.price.price / 100,
        currency: subscription.price.currency
      }]
    });
    dispatch(chooseSubscription(subscription, extension, extendingAccountSubscriptionId))
    dispatch(setReturnUrl(returnUrl))
    Redirections.goTo('/subscription/payment/data')

  }

  return (
    <div className='d-flex justify-content-center align-items-center px-md-0 px-4'>
      <div className="checkout-container my-5">
        <div className="easy-checkout">
          <h1 className="header">{t("get_your_personal_bike_fit_report")}</h1>
          <h3 className="text-white">{t("for_just") + " " + CurrencyFormatter.format(bikefitOnce?.price?.price ? bikefitOnce.price.price / 100 : 0, bikefitOnce?.price?.currency)}</h3>
          <ul>
            {
              bikefitOnceList
                .map((feature, index) => (
                  <li key={index} >
                    {t(feature)}
                  </li>
                ))
            }
          </ul>
          <p className="checkout-customer-msg">{t("already_more_than_10000_satisfied_customers")}</p>
          <hr className="border-secondary" />
        </div>
        <div className="easy-checkout-bottom d-flex flex-column flex-md-row justify-content-between align-items-start">
          <div className="d-inline-flex flex-column mt-1">
            <button className="btn btn-secondary mb-2" onClick={() => clickSubscription(bikefitOnce)}>{t("get_bike_fit_report")}</button>
            <i>{t("not_satisfied_money_back_guarantee")}</i>
          </div>
          <a
            href="https://nl.trustpilot.com/review/bestbikeadvice.com"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            <img className="trustbox-subscribe" alt="..." src={TrustScore} />
          </a>
        </div>
      </div>
    </div>
  )
}
